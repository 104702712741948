import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import FlushedGrid from "../../components/FlushedGrid"
import { PostCard } from "../../components/BlogComponents"
import Container from "../../components/UI/Container"
/* Creates a single page which loads all the posts */
const BlogRoll = ({ data }) => {
  const { edges: posts } = data.allMdx
  return (
    <Container>
      <Block
        paddingTop="60px"
        position="relative"
        display="flex"
        flexWrap="wrap"
      />
      <FlushedGrid>
        {posts &&
          posts.map(({ node }, i) => (
            <Cell
              key={node.id}
              span={i % 5 === 0 || node.frontmatter.featured ? 12 : 6}
            >
              <PostCard post={node} num={i} isHome />
            </Cell>
          )
        )}
      </FlushedGrid>
    </Container>
  )
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query MyQuery {
        allMdx(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              template: { eq: "blog-post" }
              publish: { eq: true }
            }
          }
        ) {
          edges {
            node {
              id
              body
              timeToRead
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
                author
                featured
                tags
                hero_image
              }
              imgixImage {
                fluid(maxWidth: 780) {
                  ...GatsbyImgixFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
