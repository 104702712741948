import React from "react"

import { SEOPage } from "../components/SEO"
import Layout from "../components/layout"
import BannerPlain from "../containers/BannerPlainSection"
import BlogRoll from "../containers/BlogRoll"
import BannerBG from "../images/banner-blog.svg"

export default () => {
  return (
    <Layout>
      <SEOPage
        title="Blog"
        description="Thoughts, stories, analysis from Cylynx"
      />
      <BannerPlain
        title="Thoughts, Stories, Analysis"
        background={BannerBG}
        backgroundSize="cover"
        backgroundColor="#0C6B58"
      />
      <BlogRoll />
    </Layout>
  )
}
